.greeting-div {
    width: 100%;
    /* height: 80px; */
    /* background-color: red; */
    padding-top: 20px;
    /* padding-bottom: 20px; */
}

.greeting-header {
    width: 70%;
    margin-left: 15%;
    text-align: center;
    font-weight: normal;
    font-size: 30px;
}