.square {
    width: 50px;
    height: 50px;
    border: 5px solid black;
    /* padding: 10px; */
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.row {
    display: flex;
    /* background-color: yellow; */
    width: min-content;
    /* padding: 10px; */

}