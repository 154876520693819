.description-name {
    width: 50%;
    height: 60px;
    margin: 20px 0px 0px 0px;
    margin-left: 25%;
    background-color: white;
    font-size: 26px;
    font-weight: 400;
    border-radius: 20px;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
}

.description-bottom-div {
    width: 50%;
    /* height: 40px; */
    margin-left: 25%;
    /* background-color: red; */
    border: solid black 3px;
    /* border-radius: 0px 0px 20px 20px; */
    border-radius: 20px;
    max-height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.brute-force-graphic {
    width: 90%;
    height: max-content; 
    border-radius: 20px;
    border: solid grey 2px;
    margin: 20px;
}

.bottom-description-container {
    width: 90%
}

.close-btn {
    position: absolute;
    right: 20px;
    /* top: 10px; */
}

.model-description {
    font-size: 22px;
}

.second-heading {
    font-size: 27px;
}

.description-img {
    width: 80%;
    margin-left: 10%;
}