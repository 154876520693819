.board-container {
  display: flex;
}

.board-sub-container {
  border: 1px grey solid;
  border-radius: 10px;
  padding: 20px;
  margin: 0px 10px 0px 10px;
}

.upper-container {
  width: 100%;
  display: flex;
  margin: 40px 0px 10px 0px;
  align-items: center;
  justify-content: center;
}

.game-status {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.end-txt {
    font-size: 30px;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 15px;
}

.restart-btn {
  /* background-color: rgb(249, 248, 245); */
  background-color: white;
  /* width: 100px; */
  height: 30px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px rgb(210, 0, 0);
  border-radius: 10px;
  font-weight: bold;
  font-size: 20px;
}

.restart-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.board-header {
    width: 240px;
    text-align: center;
    font-size: 23px;
}