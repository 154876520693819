footer {
    margin-top: 30px;
    width: 100%;
    border-top: solid 5px rgb(210, 0, 0);
    /* height: 0px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.credit {
    padding: 20px;
}